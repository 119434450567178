<!--行业智库详情最内层-->
<template>
  <div class="thinkTanksDiv">
    <div class="breadTopDiv">
      <el-row class="breadTop">
        <el-col>
          <top-path-list :path-obj="pathObj"></top-path-list>
        </el-col>
      </el-row>
    </div>
    <!--内容部分-->
    <div class="content-div">
      <div class="titleDiv">
        <el-row>
          <el-col :span="24">
            <span class="news_title">{{title}}</span>
          </el-col>
        </el-row>
      </div>
      <el-row>
        <el-col :span="24" style="min-height: 392px">
          <div class="w-e-text-container">
            <div v-html="newsData" data-slate-editor></div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!--增加取消下方白条-->
    <div style="height: 44px"></div>
  </div>
</template>

<script>
import topPathList from '@/components/topPathList'
import axios from 'axios'
export default {
  name: "thinkTanksDetails",
  components: { topPathList },
  data() {
    return {
      pathObj: [
        {
          name: '首页',
          path: '/'
        },
        {
          name: '行业智库',
          path: '/thinkTanks/thinkTanksDetail'
        },
        {
          name: '行业智库详情',
          path: '/thinkTanks/thinkTanksDetails'
        },
      ],
      title: '',
      newsData: '',
    }
  },
  methods: {
    getDetails() {
      let tankId = this.$route.query.tankId + ""
      //接口调用
      axios({
        method: "get",
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + "/api/portal/think/queryPeopleinfo",
        params:{tankId: tankId}
      }).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          this.title = res.data.data.tankName
          this.newsData = res.data.data.tankDetail

        } else {
          this.$message.warning("请求失败")
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })
    }
  },
  mounted () {
    //将路由带过来的tankId存储在session中，用于面包屑跳转
    let tankId = this.$route.query.tankId;
    sessionStorage.setItem('tankId', JSON.stringify(this.tankId));
    this.getDetails()
    this.newsData = ``
  },
}
</script>

<style scoped lang="less">
  @import '~@/assets/css/common.less';
  .thinkTanksDiv {
    background-color: #f8f9fa !important;
  }
  .breadTopDiv {
    text-align: center;
    .breadTop {
      width: 1200px;
      margin: auto;
    }
  }
  .content-div {
    min-height: 449px;
    margin: auto;
    padding: 32px 40px 12px 40px;
    width: 1120px;
    background: #FFFFFF;
    .titleDiv {
      text-align: center;
    }
  }
  .news_title {
    font-size: 32px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #333333;
    line-height: 48px;
    margin-top: 52px;
  }
  ::v-deep .el-row {
    margin: 20px 0;
  }

  @media screen and (max-width: 1366px) {
    .thinkTanksDiv {
      background-color: #f8f9fa !important;
    }
    .breadTopDiv {
      text-align: center;
      .breadTop {
        width: 1200px;
        margin: auto;
      }
    }
    .content-div {
      min-height: 449px;
      margin: auto;
      padding: 32px 40px 12px 40px;
      width: 1120px;
      background: #FFFFFF;
      .titleDiv {
        text-align: center;
      }
    }
    .news_title {
      font-size: 32px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #333333;
      line-height: 48px;
      margin-top: 52px;
    }
    ::v-deep .el-row {
      margin: 20px 0;
    }
  }
</style>
